import Image from 'next/image'
import { useWindowSize } from 'core/hooks'

const MainBanner = () => {
	const { width } = useWindowSize()

	const getImage = () => {
		const src = width >= 568 ? '/images/pagehead.png' : '/images/pagehead-mobile.png'
		return <Image src={src} alt="Shaping the future of biotech" layout="fill" objectFit="cover" quality={70} />
	}

	return (
		<section className="container section page-banner">
			{getImage()}
			<div className="container-inner">
				<h1>
					Shaping <br />
					the future <br />
					of biotech
				</h1>
			</div>
		</section>
	)
}

export default MainBanner
